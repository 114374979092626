<template>
    <v-container>
        <VerifyCalculatorCostingInterface />
    </v-container>
</template>

<script>
import VerifyCalculatorCostingInterface from '@/components/verify/calculator/CostingInterface'

export default {
    components: {
        VerifyCalculatorCostingInterface
    }
}
</script>